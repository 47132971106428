import React from "react";
import { graphql } from "gatsby";
import { Grid, List } from "semantic-ui-react";
import Helmet from "react-helmet";

import {
  PageLayout,
  CardNavigation,
  HeroElement,
  About,
  ContactForm,
} from "@fishrmn/fishrmn-components";

import emailCallback from "../utils/seeking-email.js";

import Layout from "../components/Layout";
import { getPageHeroImages } from "../utils/images";

export default class extends React.PureComponent {
  render() {
    const data = this.props.data.allFisherman.edges[0].node;
    const pageData = this.props.data.fishermanWebsitePage;
    const pageComponents = (pageData && pageData.components) || [];

    return (
      <Layout>
        <Helmet>
          <title>{data.businessName} | Desperately Seeking</title>
          <meta name="description" content={pageData.description} />
        </Helmet>
        <PageLayout
          hero={
            <HeroElement
              header={"Desperately  Seeking"}
              tagline={""}
              images={getPageHeroImages(pageComponents)}
              showMap={false}
              ctas={[]}
              ctaInverted={true}
              ctaColor={"white"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={false}
              containerAs={"none"}
              height={500}
              gutter={false}
            />
          }
        >
          <Grid
            stackable
            className="component-section-container rm-padding-botto"
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={12}>
              {/* <About
                content={
                  <div>
                    <h3>We are desperately seeking the following items:</h3>
                    <List
                      items={[
                        "(42) Plastic pallets",
                        "51.6 tons or 38.2 cubic yards of 3/4 chipped gravel in the color BLUE",
                      ]}
                    />
                    <h3>Needed ASAP</h3>
                  </div>
                }
                header={""}
                centerContent={true}
                headerAs={null}
                withContainer={true}
              /> */}
              <p>
              As you may be aware, there are many people in our community who are experiencing homelessness and struggling to meet their basic needs. With the warmer weather approaching, dehydration is a major concern for those without access to regular water sources.
              </p>
              <p>
              Ophelia’s Blue Vine Farm asks for your support in collecting donations to provide homeless individuals with bottles of water. By contributing to this cause, we can help ensure that those who are most vulnerable have access to this essential resource during the hot summer months.
              </p>
              <p>
              If you are interested in making a donation, please use the CONTACT US Form below to set an appointment to drop off your donation.
              </p>
              <p>
              Thank you for your consideration, and please feel free to share this message with anyone who may be interested in supporting this cause.
              </p>
            </Grid.Column>
          </Grid>

          {/* <Grid
            stackable
            className="component-section-container"
            verticalAlign={"middle"}
            textAlign={"left"}
          >
            <Grid.Column width={16}>
              <ContactForm
                buttonText={"Send"}
                centeredHeaders={true}
                className={""}
                fluidButton={false}
                header={"Contact Us"}
                inline={false}
                onSubmit={emailCallback}
                showLabels={true}
                subheader={""}
                termsCopy={""}
                withContainer={true}
              />
            </Grid.Column>
          </Grid> */}
        </PageLayout>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    allFisherman {
      edges {
        node {
          businessName
          aboutMarkdown
          phone
          email
          address {
            street
            city
            state
            zip
          }
        }
      }
    }
    fishermanWebsitePage(title: { eq: "Desperately Seeking" }) {
      description
      components {
        fastId
        order
        files {
          file
        }
      }
    }
  }
`;
